import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ThemeOne from "../shopHome/theme1";
import ThemeTwo from "../shopHome/theme2";
import { fetchShopHomeData } from "../../redux/Actions/shopHomeAction";
import axios from "axios";

function ShopHome() {
  const dispatch = useDispatch<any>();

  const shopHomeData: any = useSelector<any>((state) => state?.shopHome);
  const Theme = shopHomeData?.home_page_settings?.theme_selected;

  // const shopHomePageItems = shopHomeData?.home_page_settings
  const [settings, setSettings] = useState<any>({});
  const [pusherKey, setpusherKey] = useState<any>("")
  const base_url = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    if (Object.keys(settings).length == 0) {
      axios({
        method: "get",
        url: `${base_url}/api/settings`,
        headers: {
          "Accept": "application/json",
          'Access-Control-Allow-Methods': 'GET, POST',
        },
      }).then((response) => {
        const logo_path = response.data.data.WebsiteSettings.logo_path
        const style_fav = response.data.data.WebsiteSettings.style_fav
        const fav_icon = logo_path + "/" + style_fav
        document.getElementById('favicon')?.setAttribute('href', fav_icon)
        setpusherKey(response.data.data.WebmasterSettings.pusher_app_key)
        if (response.status != 401) {
          setSettings(response.data.data);
        }
      }).catch(function (error) {
        
      })
    }
  })

  useEffect(() => {
    dispatch(fetchShopHomeData());
  }, []); 

  return (
    <div>
      {Theme === "orange_theme" && <ThemeOne />}
      {Theme === "blue_theme" && <ThemeTwo />}
    </div>
  );
}

export default ShopHome;
