import React from 'react'
import { easytoimg } from '../../assets/img'
import "./laundryService.css";

function LaundryService() {
  return (
    <div className="easelaundry-wrapper">
        
<div className="container-fluid">
<div className="row">
<div className="col-lg-6">
<div className="easelaundry-img-right aos-init" data-aos="zoom-in-up">
<img src={easytoimg} className="img-fluid aos-init"  alt="" data-aos="zoom-in-up" />
</div>
</div>
<div className="col-lg-6">
<div data-aos="zoom-in-up" className="easelaundry-content-left text-start aos-init">
<div className="section-title text-start">
<h1 className="text-start">Ironing Service Made Easy</h1>
</div>
<div className="">
  <p>We handle clothes and household items with excellent hand ironing services. Our experienced and trained staff will provide bulk ironing jobs and specialized, delicate items such as wedding dresses or silks. When you iron your clothes, it eliminates germs not killed by the washing process.</p>
  <p>We provides Ironing services for you everyday clothing and items. When you put your items in to get them looking as neat as possible, you are looking you are very best. If you have then an item that creases easily or has a lot of folds, such as dresses and blouses,  same-day service is important to keep the original shape of these items. All daily clothes will benefit from IroningService and be promptly done with our same-day service.</p>
</div>
</div>
</div>
</div>

</div>

    </div>
  )
}

export default LaundryService;