import React from 'react'
import  "./orderOnline.css"


function orderOnline() {
    return (
        <div className="wash-fold-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 d-none d-md-block"></div>
                        <div className="col-lg-8">
                            <div className="wash-fold-card">
                                <div className="wash-fold-text">
                                    <h2>WASH <br />& <br/> FOLD</h2>
                                </div>
                                <div className="wash-fold-info">
                                    <ul>
                                        <li><i className="fa fa-check"></i> <p>Place your order an online / apps</p></li>
                                        <li><i className="fa fa-check"></i> <p>Get your laundry at a time and place that suits you</p></li>
                                        <li><i className="fa fa-check"></i> <p>We will make your clothes look new again</p></li>
                                        <li><i className="fa fa-check"></i><span> <p>We will return all your clean clothes at your doorsteps</p></span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default orderOnline