import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../redux/Actions";
import { useNavigate } from "react-router-dom";
import { setValue } from "../../utility";
import {
  fetchAddressByPincode,
  fetchGetShopByPinCode,
  fetchSaveAddress,
} from "../../redux/Actions/checkoutPageActions";
import { DebounceInput } from "react-debounce-input";
import * as yup from "yup";
import { UKPostalCode } from "../core/regex";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
const base_url = process.env.REACT_APP_BACKEND_URL;
const client_base_url = process.env.REACT_APP_BASE_URL;
const formurlDefault = "/productLists";

const searchPinCodeFormSchema = yup.object().shape({
  pinCode: yup
    .string()
    .required("Pin code is required")
    .matches(UKPostalCode, "Enter a valid Pin code")
    .trim()
    .max(8, "Enter a valid Pin code"),
});

const addressFormSchema = yup.object().shape({
  address: yup
    .object()
    .shape({
      label: yup.string().required("Address is required to proceed"),
      value: yup.string().required("Address is required to proceed"),
    })
    .test("address.value", "Address is required to proceed", (value: any) => {
      if (value.value == "") {
        return false;
      } else return true;
    })
    .required("Address is required to proceed"),
});

const postalCodesSearchData = {
  result: {
    Response: {
      response_code: "1",
      response_message: "success",
    },
    data: [[]],
  },
};

function AreaNotCovered() {
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const [toClosed, settoClosed] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const state: any = useSelector((state) => state);
  const [postalCodesSearch, setPostalCodesSearch] = useState<any | null>(
    postalCodesSearchData
  );
  const [checkWithNewPin, setCheckWithNewPin] = useState(true);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const areaOptions: any = useSelector<any>(
    (state) => state?.addressByPincode?.data?.address_list
  );
  const {
    handleSubmit: handlePinCodeSubmit,
    control: pinCodeFormControl,
    setError: setPinCodeFormError,
    setValue: setPinCodeFormValue,
    formState: { errors: pinCodeFormError },
  } = useForm({
    resolver: yupResolver(searchPinCodeFormSchema),
    mode: "onChange",
    defaultValues: {
      pinCode: "",
    },
  });

  const {
    handleSubmit: handleAddressSubmit,
    control: addressFormControl,
    setError: addressFormSetError,
    setValue: setAddressFormValue,
    getValues: getValuesAddressForm,
    reset: resetAddressForm,
    clearErrors,
    formState: { errors: addressFormError },
  } = useForm({
    resolver: yupResolver(addressFormSchema),
    mode: "onChange",
    defaultValues: {
      address: { value: "", label: "Select your address..." },
    },
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    settoClosed(true);
    const headers = {
      Accept: "application/json",
    };

    // axios
    //   .get(`${base_url}/api/get_postal_codes?keyword=${postalCodeValue}`, {
    //     headers: headers,
    //   })
    //   .then( async (e) => {
    //     setPostalCodesSearch({ result: e.data });
    //     //alert(JSON.stringify( e.data.data[0]) )
    //     //alert(JSON.stringify( e.data.data[0].length) +" "+ postalCodesSearch.result.data.length +(postalCodesSearch.result.data[0].length==0) );
    //     let lengthvar = e.data.data[0].length;
    //     if (lengthvar != 1) {
    //       //  alert(JSON.stringify( e.data.data[0].length.toString))
    //       window.location.href = `/areanotcovered`;

    //       //window.open(`${client_base_url}/areanotcovered`);
    //       return false;
    //     } else if (lengthvar == 1) {

    //     }
    //   });

    const results = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(postalCodeValue))
    );

    if (!results) {
      navigate("/areaNotCovered");
      settoClosed(false);
      return;
    }
    navigate("/productLists");

    // alert(`Please enter a valid postal code`)
  };

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [state]);

  const toClose = () => {
    settoClosed(true);
  };

  const handleChange = (e: any) => {
    // setPostalCodeList(e.target.value);
    const { value } = e.target;
    const updateValue = value.replace(/\s/g, "");
    if (value.length > 0) {
      dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);
  };

  const onSearch = (searchTerm: any) => {
    // setPostalCodeList(searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode", searchTerm.toUpperCase());
    // dispatch(fetchPostalCodes(searchTerm));
  };

  const handleAddressChange = async (selected: any) => {
    setSelectedAddress(selected);
    setAddressFormValue("address", selected);
    if (getValuesAddressForm("address")?.value == "") {
      addressFormSetError("address", {
        type: "server",
        message: "Address is required to proceed",
      });
    } else {
      clearErrors();

      if (localStorage.getItem("token")) {
        // handle api
        let postalCode: any = localStorage.getItem("postalCode");
        const contact_name = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.name;
        const mobile_number = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.mobile;
        const address = selected?.value?.split(",");
        const payloadData = {
          address_type: "pickup",
          address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${address[0].trim()}","line_2":"${address[1].trim()}","line_3":"${address[2].trim()}","line_4":"${address[3].trim()}","locality":"${address[4].trim()}","city":"${address[5].trim()}","county":"${address[6].trim()}","label":"Home","pincode":"${JSON.parse(
            postalCode
          )}"}`,
        };
        const uniqueAddress = await dispatch(fetchSaveAddress(payloadData));
        if (uniqueAddress) {
          navigate("/productLists");
        } else {
          addressFormSetError("address", {
            type: "server",
            message: "Unable to set address try again later",
          });
        }
      } else {
        localStorage.setItem(
          "selectedAddressBeforeLogin",
          JSON.stringify(selected)
        );
        navigate("/productLists");
      }
    }
  };

  const submitPostalForm = async (data: any) => {
    localStorage.setItem("postalCode", JSON.stringify(data.pinCode));
    const shop = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(data.pinCode))
    );
    if (shop) {
      const result = await dispatch(fetchAddressByPincode());
      if (result.status == 200 && result.data.data?.address_list.length > 0) {
        setCheckWithNewPin(false);
      } else {
        setPinCodeFormError("pinCode", {
          type: "server",
          message: "Area not covered",
        });
      }
    } else {
      setPinCodeFormError("pinCode", {
        type: "server",
        message: "Area not covered",
      });
    }
  };

  return (
    <div>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Area Not Covered</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span>Area Not Covered</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="not-covered">
        <div className="container">
          <div className="row justify-content-center no-search">
            <div className="col-md-5">
              <div className="form-search">
                {toClosed === false && (
                  <div className="no-search-cont" id="notcoverdalert">
                    <p>Sorry, we don't cover your postal code yet!.</p>
                    <i className="btn-close" onClick={toClose}></i>
                  </div>
                )}
                <div className="no-search-hint">
                  <p>Please check for another postal code.</p>
                </div>
                <form method="get" onSubmit={handleSubmit}>
                  <div className="notcovered">
                    <div className="postcodeform">
                      <form onSubmit={handlePinCodeSubmit(submitPostalForm)}>
                        <div
                          className={`${
                            checkWithNewPin == false ? "d-none" : ""
                          }`}
                        >
                          <small className="text-danger postvalidation">
                            {pinCodeFormError.pinCode?.message}
                          </small>
                          <div className="postcodeform">
                            <Controller
                              name="pinCode"
                              control={pinCodeFormControl}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  id="postcode-input"
                                  {...field}
                                  placeholder="Enter Zipcode"
                                  className={`ui-autocomplete-input ${
                                    pinCodeFormError.pinCode ? "invalid" : ""
                                  }`}
                                />
                              )}
                            />
                            <button
                              className="btn hover-btn"
                              id="book_now_btn"
                              type="submit"
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </form>
                      <form>
                        <div
                          className={`adrsselectform ${
                            checkWithNewPin == true ? "d-none" : ""
                          }`}
                        >
                          <div className="adrsselectdd">
                            <Controller
                              name="address"
                              control={addressFormControl}
                              render={({ field }) => (
                                <Select
                                  placeholder="Select your address"
                                  {...field}
                                  value={selectedAddress}
                                  className={`${
                                    addressFormError.address ? "invalid" : ""
                                  }`}
                                  onChange={handleAddressChange}
                                  onFocus={() => {
                                    if (
                                      getValuesAddressForm("address").value ==
                                      ""
                                    ) {
                                      addressFormSetError("address", {
                                        type: "server",
                                        message:
                                          "Address is required to proceed",
                                      });
                                    } else {
                                      clearErrors();
                                    }
                                  }}
                                  options={areaOptions}
                                />
                              )}
                            />
                            <small className="text-danger postvalidation">
                              {addressFormError.address?.message}
                            </small>
                          </div>
                          <div className="adrsselect-or">OR</div>
                          <div className="adrsselect-link">
                            <a
                              className="cursor-pointer"
                              onClick={() => {
                                setCheckWithNewPin(true);
                              }}
                            >
                              Check with another Zipcode
                            </a>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AreaNotCovered;
